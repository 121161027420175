<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="isCreated ? '新增' : '编辑'"
    :visible.sync="show"
    width="1200px"
    class="add-account"
    v-loading="loading"
  >
    <el-form
      size="small"
      :model="params"
      ref="form"
      label-width="100px"
      :rules="rules"
    >
      <el-divider content-position="left">产品信息</el-divider>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="产品名称" prop="name">
            <SelectGoodsName
              :code.sync="params.code"
              :name.sync="params.name"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品料号" prop="code">
            <SelectGoodsCode
              :code.sync="params.code"
              :name.sync="params.name"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="生产日期">
            <el-date-picker
              type="date"
              style="width: 100%"
              v-model="params.manufactureDate"
              placeholder="请选择"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() > Date.now()
                }
              }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品序列号" prop="sn">
            <el-input
              v-model="params.sn"
              placeholder="请输入"
              oninput="value=value.replace(/[\u4e00-\u9fa5|\s]/g, '')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="生产子订单号" prop="subOrderCode">
            <el-input
              v-model="params.subOrderCode"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="保修截止日期">
            <el-date-picker
              type="date"
              style="width: 100%"
              v-model="params.maintenanceDeadline"
              placeholder="请选择"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() < Date.now()
                }
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-divider content-position="left">配件信息</el-divider>
        <div v-for="(subItem, index) in params.details" :key="index">
          <el-col :span="8">
            <el-form-item
              :label="`配件类型${params.details.length > 1 ? index + 1 : ''}`"
              :prop="'details.' + index + '.typeId'"
              :rules="rules.typeId"
            >
              <el-select
                style="width: 100%"
                v-model="subItem.typeId"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in partsTypeList"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="">
              <div style="text-align: right; height: 34px">
                <i
                  class="el-icon-delete"
                  v-if="params.details.length > 1"
                  style="font-size: 22px; color: #c7004a"
                  @click="params.details.splice(index, 1)"
                ></i>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              label="配件料号"
              :prop="'details.' + index + '.code'"
              :rules="rules.fittingCode"
            >
              <SelectGoods
                searchKey="code"
                :spec.sync="subItem.mode"
                :code.sync="subItem.code"
                :name.sync="subItem.name"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              label="配件名称"
              :prop="'details.' + index + '.name'"
              :rules="rules.fittingName"
            >
              <SelectGoods
                searchKey="name"
                :spec.sync="subItem.mode"
                :code.sync="subItem.code"
                :name.sync="subItem.name"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              label="配件型号"
              :prop="'details.' + index + '.mode'"
              :rules="rules.fittingMode"
            >
              <SelectGoods
                searchKey="spec"
                :spec.sync="subItem.mode"
                :code.sync="subItem.code"
                :name.sync="subItem.name"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="配件序列号">
              <el-input
                v-model="subItem.sn"
                placeholder="请输入"
                oninput="value=value.replace(/[\u4e00-\u9fa5|\s]/g, '')"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="保修截止日期" prop="maintenanceDeadline">
              <el-date-picker
                style="width: 100%"
                v-model="subItem.maintenanceDeadline"
                placeholder="请选择"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                :picker-options="{
                  disabledDate(time) {
                    return time.getTime() < Date.now()
                  }
                }"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库日期" prop="outDate">
              <el-date-picker
                style="width: 100%"
                v-model="subItem.outDate"
                placeholder="请选择"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
        </div>
        <el-col :span="24" style="text-align: center">
          <el-button
            size="small"
            style="width: 100%; margin: 0 auto"
            icon="el-icon-plus"
            v-if="params.details.length < 10"
            @click="params.details.push({})"
          >
            添加配件
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <span style="float: left">已连续添加{{ count }}条</span>
      <el-button type="primary" v-if="isCreated" @click="submitForm('add')">
        保存并继续添加
      </el-button>
      <el-button type="primary" @click="submitForm">保存</el-button>
      <el-button
        @click="
          $emit('updateList')
          show = false
        "
      >
        取消
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: {
    partsTypeList: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    const rules = {
      name: [{ required: true, message: '请输入产品名称' }],
      code: [{ required: true, message: '请输入产品料号' }],
      sn: [{ required: true, message: '请输入产品序列号' }],
      masterSn: [{ required: true, message: '请输入产品序列号' }],
      typeId: [{ required: true, message: '请选择配件类型' }],
      fittingMode: [{ required: true, message: '请选择配件型号' }],
      fittingCode: [{ required: true, message: '请选择配件料号' }],
      fittingName: [{ required: true, message: '请选择配件名称' }]
    }

    return {
      loading: false,
      rules,
      params: {
        manufactureDate: new Date(),
        details: [{}]
      },
      count: 0
    }
  },
  created() {
    if (!this.isCreated) {
      this.params = { ...this.currentItem }
    }
  },

  methods: {
    async submitForm(type) {
      console.log(this.params)
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            let params = { ...this.params }
            params.manufactureDate = params.manufactureDate
              ? this.$utils.dateFormat(params.manufactureDate, 'YYYY-mm-dd')
              : ''
            params.maintenanceDeadline = params.maintenanceDeadline
              ? this.$utils.dateFormat(params.maintenanceDeadline, 'YYYY-mm-dd')
              : ''
            params.details.forEach(item => {
              item.maintenanceDeadline = item.maintenanceDeadline
                ? this.$utils.dateFormat(item.maintenanceDeadline, 'YYYY-mm-dd')
                : ''
              item.outDate = item.outDate
                ? this.$utils.dateFormat(item.outDate, 'YYYY-mm-dd')
                : ''
            })

            if (this.isCreated) {
              await this.$api.workOrder.addProduct(params)
            } else {
              await this.$api.workOrder.updateProduct(params)
            }

            this.$message.success('操作成功')
            if (type == 'add') {
              this.count++
              this.params.sn = ''
              this.params.details.forEach(item => {
                item.sn = ''
              })
              this.params.details.push({})
            } else {
              this.$emit('updateList')
              this.show = false
            }
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
          }
        }
      })
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped></style>
