<template>
  <Page>
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="产品序列号" prop="sn">
          <el-input placeholder="请输入" v-model="params.sn"></el-input>
        </el-form-item>
        <el-form-item label="产品名称" prop="name">
          <el-input placeholder="请输入" v-model="params.name"></el-input>
        </el-form-item>
        <el-form-item label="产品料号" prop="code">
          <el-input placeholder="请输入" v-model="params.code"></el-input>
        </el-form-item>
        <el-form-item label="生产子订单号" prop="subOrderCode">
          <el-input
            placeholder="请输入"
            v-model="params.subOrderCode"
          ></el-input>
        </el-form-item>
        <el-form-item label="配件序列号" prop="partsSn">
          <el-input placeholder="请输入" v-model="params.partsSn"></el-input>
        </el-form-item>
        <el-form-item label="保修截止日期" prop="time1">
          <el-date-picker
            style="width: 240px"
            type="daterange"
            v-model="params.time1"
            placeholder="请选择"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="生产日期" prop="time2">
          <el-date-picker
            style="width: 240px"
            type="daterange"
            v-model="params.time2"
            placeholder="请选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="{
              disabledDate(time) {
                return time.getTime() > Date.now()
              }
            }"
          />
        </el-form-item>
      </el-form>
    </template>
    <div style="margin-bottom: 12px">
      <el-button v-auth="'insert'" type="primary" @click="toInsert()">
        新 增
      </el-button>
      <el-button v-auth="'export'" @click="toExport1()">导出产品列表</el-button>
      <el-button v-auth="'export'" @click="toExport2()">导出配件明细</el-button>
    </div>
    <div class="table-wrap">
      <el-table
        border
        :data="tableData"
        v-loading="loading"
        @expand-change="getDetail"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form
              label-position="left"
              inline
              v-for="(item, index) in props.row.details"
              :key="index"
              style="padding-left: 50px"
            >
              <el-form-item
                :label="`${
                  props.row.details.length > 1 ? index + 1 : ''
                }   配件类型:`"
                style="margin: 0 70px 0 0"
              >
                <span>{{ item.typeStr || '-' }}</span>
              </el-form-item>
              <el-form-item label="配件名称：" style="margin: 0 70px 0 0">
                <span>{{ item.name || '-' }}</span>
              </el-form-item>
              <el-form-item label="配件料号：" style="margin: 0 70px 0 0">
                <span>{{ item.code || '-' }}</span>
              </el-form-item>
              <el-form-item label="配件型号：" style="margin: 0 70px 0 0">
                <span>{{ item.mode || '-' }}</span>
              </el-form-item>
              <el-form-item label="配件序列号：" style="margin: 0 70px 0 0">
                <span>{{ item.sn || '-' }}</span>
              </el-form-item>
              <el-form-item label="保修截止日期：" style="margin: 0 70px 0 0">
                <span>{{ item.maintenanceDeadline || '-' }}</span>
              </el-form-item>
              <el-form-item label="出库日期：" style="margin: 0 70px 0 0">
                <span>{{ item.outDate || '-' }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="sn"
          label="产品序列号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="name"
          label="产品名称"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="code"
          label="产品料号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="manufactureDate"
          label="生产日期"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="subOrderCode"
          label="生产子订单号"
        ></el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="maintenanceDeadline"
          label="保修截止日期"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="qualityInspector"
          label="质检人员"
        />

        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="operator"
          label="操作人"
        ></el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="updateTime"
          label="更新时间"
        ></el-table-column>
        <el-table-column
          header-align="center"
          show-overflow-tooltip
          label="操作"
          width="200"
        >
          <template slot-scope="{ row }">
            <el-button size="medium" type="text" @click="toUpdate(row)">
              编辑
            </el-button>

            <el-button size="medium" type="text" @click="toDelete(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      :currentItem="currentItem"
      :partsTypeList="partsTypeList"
      v-if="showUpdateModal"
      :visible.sync="showUpdateModal"
      @updateList="getData"
    />
  </Page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'
export default {
  components: { AddModal },
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      showUpdateModal: false,
      params: {
        page: 1,
        limit: 10
      },
      total: 0,
      tableData: [],
      currentItem: null,
      partsTypeList: []
    }
  },
  created() {
    this.getPartsTypeList()
  },
  methods: {
    async getDetail(row, expandedRows) {
      if (expandedRows.length > 0) {
        let data = await this.$api.workOrder.getProductDetail(row.id)
        row.details = (data || []).map(item => {
          item.typeStr =
            this.partsTypeList.find(_ => _.id === item.typeId)?.name || ''
          item.name = item.fittingName || ''
          item.code = item.fittingCode || ''
          item.mode = item.fittingMode || ''
          item.sn = item.fittingSn || ''
          item.maintenanceDeadline = item.warrantyDate || ''
          item.outDate = item.outboundDate || ''
          return item
        })
      }
    },
    async getPartsTypeList() {
      this.partsTypeList = await this.$api.workOrder.getPartsTypeList()
    },
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.time1) {
          params.maintenanceStartDate = params.time1[0]
          params.maintenanceEndDate = params.time1[1]
          params.time1 = undefined
        } else {
          params.maintenanceStartDate = ''
          params.maintenanceEndDate = ''
        }
        if (params.time2) {
          params.manufactureStartDate = params.time2[0]
          params.manufactureEndDate = params.time2[1]
          params.time2 = undefined
        } else {
          params.manufactureStartDate = ''
          params.manufactureEndDate = ''
        }
        const { records, total } = await this.$api.workOrder.getProductPage(
          params
        )
        this.tableData = (records || []).map(item => {
          item.details = []
          return item
        })
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async toInsert() {
      this.currentItem = null
      this.showUpdateModal = true
    },
    async toImport() {
      this.showImportModal = true
    },
    async toExport1() {
      await this.$api.workOrder.exportProduct(this.params)
      this.$message.success('导出成功')
    },
    async toExport2() {
      await this.$api.workOrder.exportProductDetail(this.params)
      this.$message.success('导出成功')
    },
    async toUpdate(row) {
      await this.getDetail(row, [row])
      this.currentItem = row
      console.log(row, this.partsTypeList)
      this.showUpdateModal = true
    },

    async toDelete(row) {
      try {
        await this.$confirm('确定删除产品信息吗？', '温馨提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.workOrder.delProduct(row.id)
        this.getData()
        this.$message.success('删除成功')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .page-content {
  padding-top: 0px !important;
}
</style>
